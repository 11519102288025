@import '../../variables.scss';

$arrow-size: 7px;
$arrow-color: var(--secondary-backgrond-color);

.arrowUp {
  width: 0;
  height: 0;
  border-left: $arrow-size solid transparent;
  border-right: $arrow-size solid transparent;

  border-bottom: $arrow-size solid $arrow-color;
}

.arrowDown {
  width: 0;
  height: 0;
  border-left: $arrow-size solid transparent;
  border-right: $arrow-size solid transparent;

  border-top: $arrow-size solid $arrow-color;
}