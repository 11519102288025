@import '../variables.scss';
// @import '../../../media-queries.scss';

.details {
  background-color: $accordion-background-color;
}

.accordionOpener {
  display: flex;
  flex: 1;
  justify-content: center;
  color: blue;
}