@import '../../variables.scss';
@import '../../media-queries.scss';


.dataContainer {
  margin-top: $seasonality-vertical-spacing;

  @include lt-lg {
    margin-left: calc($app-horizontal-margin / 2);
    margin-right: calc($app-horizontal-margin / 2);
  }
}

.graphContainer {
  margin-top: $seasonality-vertical-spacing;
  padding-bottom: $app-vertical-margin;

  @include lt-lg {
    margin-left: calc($app-horizontal-margin / 2);
    margin-right: calc($app-horizontal-margin / 2);
  }
}

.tableContainer {
  position: relative;
  margin-top: calc($seasonality-vertical-spacing / 2);

  @include lt-lg {
    margin-left: calc($app-horizontal-margin / 3);
    margin-right: calc($app-horizontal-margin / 3);
  }
}

.doubleGraphContainer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  margin-top: calc($seasonality-vertical-spacing * 2);

  @include lt-md {
    flex-direction: column;
  }
}

.priceMoveContainer {
  position: relative;
  margin: auto;
  min-width: 60%;
  background-color: #eaeaea;
  padding: 25px;
  border-radius: 26px;
  margin-right: 10px;

  @include gt-md {
    margin-right: 40px;
  }

  @include lt-md {
    margin-top: calc($seasonality-vertical-spacing * 2);
    flex-direction: column;
    min-width: 80%;
    margin-right: auto;
  }

  canvas {
    background-color: #fff;
  }
}

.profitLossContainer {
  position: relative;
  min-width: 30%;
  background-color: #f0f0f0;
  padding: 25px;
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;

  @include lt-md {
    margin-top: calc($seasonality-vertical-spacing * 2);
    flex-direction: column;
    min-width: 80%;
  }

  canvas {
    background-color: #fff;
  }
}