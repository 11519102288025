$midnight: #273744;
$midnight-30: #2737444D;
$midnight-60: #27374499;


$dustyBlue: #607A8F;
$dusty-blue-30: #607A8F4D;
$dusty-blue-60: #607A8F99;


$dusk: #636C9C;
$dusk-30: #636C9C4D;
$dusk-60: #636C9C99;


$dark-grey: #384044;
$dark-grey-30: #3840444D;
$dark-grey-60: #38404499;


$slate: #7E8C95;
$slate-30: #7E8C954D;
$slate-60: #7E8C9599;


$sand: #DFC08F;
$sand-60: #DFC08F99;
$sand-30: #DFC08F4D;


$moss: #728F79;
$moss-30: #728F794D;
$moss-60: #728F7999;


$gold: #F6C54F;
$gold-30: #F6C54F4D;
$gold-60: #F6C54F99;


$orange: #D17936;
$orange-30: #D179364D;
$orange-60: #D1793699;


$clover: #9DB850;
$clover-30: #9DB8504D;
$clover-60: #9DB85099;


$sky: #7FC9DE;
$sky-30: #7FC9DE4D;
$sky-60: #7FC9DE99;


$light-grey: #D9D9D9;
$light-grey-30: #D9D9D94D;
$light-grey-60: #D9D9D999;