@import 'variables';
@import 'media-queries';

.appContainer {
  padding: 0px 0px 75px 0px;
  height: 100%;
  min-height: 100%; 
  overflow: auto;

  @include gt-md {
    padding: 0px $app-horizontal-margin $app-vertical-margin $app-horizontal-margin; 
  }

}