@import '../../media-queries.scss';

h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h2 {
  font-style: italic;
  font-weight: normal;
  display: block;
  font-size: 1.1em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.flexInstructionText {
  max-width: 90%;

  &::after {
    content: 'Select structures on the left, then select product combinations from the right';
  }

  @include lt-md {
    &::after {
      content: 'Select structures from the top, then select product combinations from below';
    }
  }

}

h4 {
  display: block;
  margin-block-start: 0px;
  margin-block-end: 20px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.selectionsContainer {
  display: flex;
  flex-direction: row;
  min-width: 90%;
  padding: 0px 10%;
  margin-top: 40px;

  @include lt-md {
    flex-direction: column;
  }
}

.structureSelectionRow {
  cursor: pointer;
  border-radius: 10px;
  margin-right: 20px;
  margin-top: 8px;

  &:hover {
    background-color: #dedede;
    text-decoration: underline;
  }

  max-width: 220px;

  @include lt-md {
    margin-left: 35%;
  }
}

.preamble {
  padding: 0% 15%;
  justify-content: center;
  text-align: center;
}

.centeredColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-bottom: 80px;
}

.termToggleContainer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  min-width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;

  @include lt-sm {
    flex-direction: column;
  }
}

.termToggle {
  max-width: 30%;
  min-width: 30%;
  padding: 2%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;

  background-color: #dedede;
  border-radius: 20px;
  border: 1px solid #111;
  box-shadow: #dedede66 0px 2px 2px 2px;

  cursor: default;
  position: relative;
}

.termToggleAnimated {
  @extend .termToggle;

  &:hover {
    background-color: #cdcdcd;
    cursor: pointer;
    box-shadow: #888888cc 0px 2px 2px 2px;

    :last-child {
      text-decoration: underline;
    }
  }
}

.termToggleSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding-left: 10px;
  cursor: pointer;
}

.termToggleText {
  margin-top: 2%;
  text-align: center;
}

.buttonContainer {
  margin-top: 5%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
}

.doubleButtonContainer {
  margin-top: 5%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-evenly;
}

.successText {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 100%;
  justify-content: center;
  align-items: center;
}

.structureSelections {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.combinationSelections {
  display: flex;
  flex-direction: column;
  flex: 3;
  justify-content: center;
}


.successContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1 1 auto;
  margin: 7.5% 25% 12% 25%;
  padding: 0% 5%;
  border: 2px solid #777;
  border-radius: 50px;
  background-color: #eaeaea;
  box-shadow: 0 0 20px -1px rgba(0, 0, 0, 0.26);
  z-index: 200;

  @include lt-lg {
    margin: 3.5% 12.5% 6% 12.5%;
  }

  @include lt-md {
    margin: 2%;
  }
}

.successSubtext {
  margin-top: 10px;
}

.verticalEvenSpaced {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 300px;
}

.areYouSure {
  margin-top: 45px;
  text-align: center;
}