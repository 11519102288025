
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.helperText {
  color: red;
  margin: 0;
  font-size: 14px;
}

.textInput {
  border: 1px solid black;
  border-radius: 10;
  min-height: 22px;
  max-height: 22px;
  max-width: 80px;
  min-width: 80px;
  &:hover{
    background-color: lightgray;
  }
}