@import './suncorColors.scss';

$primary: #122531;
$primary-light: #1d303b;
$secondary: #e4ac00;
$content-background: white;

$table-border-color: #dadee2;
$table-text-secondary-color: #8f9091;

$navBarWidth: 220px;
$header-height: 75px;

$app-content-padding: 20px;
$expanded-recommendation-content-padding: 12px;

$menu-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);



// COLORS
$light-background: $light-grey-60;
$accordion-background-color: $light-background;
$accordion-summary-color: $light-background;
$success-color: $clover;


// SPACING
$app-horizontal-margin: 100px;
$app-vertical-margin: 75px;
$data-table-margins: 30px;
$seasonality-vertical-spacing: 25px;

// BORDERS
$table-borders: 1px solid black;