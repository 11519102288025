@import '../../../media-queries.scss';

$debug: false;

.helpContainer {
  @if $debug==true {
    background-color: red;
  }

  color: white;
  cursor: pointer;
  border-radius: 999px;
  padding: 5px 14px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: orange;
    background-color: #ffffff1A;
  }
}

.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  padding: 20px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24px;
  cursor: default;

  display: flex;
  flex-direction: column;
}

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  padding-bottom: 20px;
}

.title {
  @if $debug==true {
    background-color: red;
  }

  padding: 20px 0px 20px 4px;
  font-size: 22px;
  font-weight: 600;
  text-decoration: underline;
}

.quickLinks {
  @if $debug==true {
    background-color: red;
  }

  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  overflow-x: auto;
  max-height: 6%;
  max-width: 100%;

  @include lt-sm {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.link {
  color: blue;
  cursor: pointer;
  white-space: nowrap;
  margin: 0px 10px;

  &:hover {
    text-decoration: underline;
  }
}

.close {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

.listContainer {
  @if $debug==true {
    background-color: yellow;
  }

  padding: 10px 30px 30px 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

}

.contentSubtext {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f3f3f3;
  font-size: 18px;

  nav {
    padding: 0px;
  }
}