@import '../../variables.scss';
@import '../../media-queries.scss';

.container {
  overflow-x: auto;
}

.cellContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.table {
  margin-top: $seasonality-vertical-spacing;
  width: 100%;

  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  overflow: visible;
  border-collapse: collapse;
  border-spacing: 0px;
  outline: currentcolor none 0px;
}

%cell {
  min-width: max-content;
  text-align: center;
  border-top: 2px solid var(--background-color);

  padding: 10px;

  font-family: NotoSans, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  font-weight: normal;
}

.headerCell {
  @extend %cell;
  font-weight: 600;
  white-space: nowrap;
}

.bodyCell {
  @extend %cell;
  font-weight: normal;
}

.selectedBodyCell {
  @extend %cell;
  background-color: var(--secondary-backgrond-color);
}