@import '../../media-queries.scss';
@import '../../variables.scss';


%card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 10px;
  cursor: default;
}

.profit {
  @extend %card;
  background-color: #54A1E540;
  margin-right: 10%;
}

.loss {
  @extend %card;
  background-color: #ED6E8540;
  margin-left: 10%;
}

.bold {
  font-family: NotoSans, sans-serif;
}

.small {
  font-family: NotoSans, sans-serif;
  font-weight: lighter;
  font-size: small;
}

.titleText {
  font-size: 18px;
  letter-spacing: 0.01em;
  text-align: center;
}


.totals {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dropdowns {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}