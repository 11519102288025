@import '../../media-queries.scss';

$margin: 10px;

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex: 1 0.5 auto;

  @include lt-md {
    margin-bottom: $margin;
  }
}


.tuple {
  margin: 0px $margin 0px $margin;
  text-align: center;
  cursor: default;
}