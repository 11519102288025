
.popover {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 10px;
  max-height: 500px;
  .item:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.button {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;

  padding: 4px 15px 4px 5px;
  border-radius: 4px;
  border: 1px solid blue;
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: transparent
  }
  color: blue;
  position: relative;


  box-sizing: border-box;
  max-width: max-content;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.buttonSymbol {
  position: absolute;
  top: 6px;
  right: 0;
  font-size: 11px;
}


.ripple {
  width: 40px;
  height: 40px;
  position: absolute;
  background: #63a4ff;
  display: block;
  content: "";
  border-radius: 9999px;
  opacity: 1;
  animation: 1.7s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}
