//

%row {
  margin-top: 10px;
  padding: 7px;
  border-radius: 10px;
}

.selectableRow {
  @extend %row;

  &:hover {
    background-color: #dedede;
    text-decoration: underline;
  }
}

.selectedRow {
  @extend %row;
}