$ratio: 0.765;

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  height: var(--toggle-height);
  width: var(--toggle-width);
}


/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  height: var(--toggle-height);
  width: var(--toggle-width);
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;

  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: var(--inner-size);
  width: var(--inner-size);
  left: var(--toggle-padding);
  bottom: var(--toggle-padding);
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(var(--inner-size));
  -ms-transform: translateX(var(--inner-size));
  transform: translateX(var(--inner-size));
}


.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

%text {
  padding-left: 10px;
  cursor: default;
}

.enabledText {
  @extend %text;
  color: black;
}

.disabledText {
  @extend %text;
  color: #ccc;
}

:root {
  --toggle-height: 34px;
  --inner-size: 26px;
  --toggle-width: 60px;
  --toggle-padding: 4px;
}