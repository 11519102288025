@import '../../../media-queries';


.container {
  display: flex;
  flex-direction: row;
  flex: 2;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.group {
  display: flex;
  flex: 2;

  @include lt-md {
    flex-direction: column;
  }

  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 10px;

  @include lt-sm {
    margin-top: 10px;
  }
}

.buttonGroup {
  @extend .group;
  flex: 1;
}

.formula {
  padding: 0px 10px 0px 10px;
}

.fetchDisabledText {
  text-align: center;
  color: #ff0000A0;
}