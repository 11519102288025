.icon {
  // opacity: 0.9;

  width: 1.1em;
  height: 1.1em;
  font-size: 0.9em;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
  border: 2px solid gray;
  color: gray;
  margin-left: 5px;
}

// not working.... would like this to work to limit the height of some of the longer tooltips
// ie. AnnualTable headers
// .scroller {
//   max-height: 200px;
//   overflow-x: scroll;

//   /* width */
//   ::-webkit-scrollbar {
//     width: 10px;
//   }

//   /* Track */
//   ::-webkit-scrollbar-track {
//     background: red;
//     // background: #f1f1f1;
//   }

//   /* Handle */
//   ::-webkit-scrollbar-thumb {
//     background: blue;
//   }

//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
//     background: lightblue;
//   }
// }