.link {
  color: white;
  font-size: normal;
  width: fit-content;
  margin-top: 8px;
  padding: 2px 8px 2px 8px;
  border-radius: 999px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #ffffffcc;
    background-color: #ffffff1A;
  }
}