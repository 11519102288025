@import '../../../suncorColors.scss';
@import '../../../media-queries';

$spacing: 10px;

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  padding: $spacing;
  border: 2px solid;
  border-color: $light-grey;
  white-space: nowrap;
}

.row {
  display: flex;
  flex: 1;
  flex-direction: row;

  @include lt-md {
    flex-direction: column;
  }

  flex-wrap: wrap;
}

.item {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: calc($spacing / 2);
}