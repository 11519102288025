@import '../../../variables.scss';

.wrapper {
  position: fixed;
  width: 100vw;
  overflow: hidden;
  height: 100vh;
  background-color: $primary-light;
  display: flex;
  justify-content: center;
}

.body {
  margin-top: 20%;
  color: #dde0e2;
  text-align: center;
}

.title {
  font-size: 48px;
}

.subtitle {
  font-size: 24px;
}

.email {
  color: #edab00;
}