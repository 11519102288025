@import '../Tables.module.scss';
@import '../../../variables.scss';

$width: 110px;

.sticky {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 0px 10px 0px 10px;
}

.table {
  @extend .table;
  margin-top: 0px;
}

.headerCell {
  min-height: calc($width / 2);
  background-color: white;
  font-family: NotoSans, sans-serif;
  line-height: 1.1rem;
  letter-spacing: 0.010em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  cursor: default;
}

.headerText {
  display: flex;
  max-width: $width;
  white-space: normal;
  word-wrap: normal;
  word-break: normal;
}

.sortableHeaderCell {
  @extend .headerCell;
  cursor: pointer;
}

.bodyCell {
  @extend .bodyCell;
  font-weight: lighter;
  font-size: 0.7375rem;
}

.units {
  text-align: center;
  font-weight: 600;
  margin-bottom: 25px;
  margin-top: 25px;
  cursor: default;
}

.container {
  max-height: 320px;
  overflow: scroll;
}

.config {
  position: absolute;
  top: 20px;
  left: 0px;
  z-index: 999;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  padding: 5px;

  .tooltip {
    display: none;
  }

  &:hover {
    .tooltip {
      display: inherit;
      position: absolute;
      white-space: nowrap;
      top: -5px;
      left: 25px;
      padding: 5px;
      border: 1px solid black;
      border-radius: 5px;
      background-color: opacify($color: $accordion-background-color, $amount: 1);
    }
  }
}

.configRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 20px 8px 20px;
  cursor: pointer;

  &:hover {
    background-color: $accordion-background-color;
  }
}

.hiddenConfigRow {
  @extend .configRow;
  color: #a0a0a0; // TODO hidden color as a variable

  &:hover {
    color: unset;
  }
}

.paddingLeft {
  padding-left: 8px;
}

.underlineBold {
  text-decoration: underline;
  font-weight: bold;
}

.toggleContainer {
  margin-top: 10px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}