$margin: 5px;

.title {
  font-size: large;
  font-family: NotoSans, sans-serif;
  font-weight: 600;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: $margin;
}

.expander {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  margin-right: $margin*4;
  margin-left: $margin;

  /* "Button" styling */
  border: 1px solid opacify($color: blue, $amount: 1.0);
  border-radius: 50px;
  padding: 0px 10px;
  background-color: transparent;
}


.tooltipContainer {
  margin-left: $margin;
}

.bold {
  font-weight: bold;
}

.note {
  display: flex;
  flex-direction: column;
}