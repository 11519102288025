@import '../../../variables.scss';
@import '../../../suncorColors.scss';
@import '../../../media-queries.scss';

.root {
  background-color: $light-grey;
  border-radius: 10px;
  padding: 0px 40px 20px 40px;
  margin-top: 10px;
  overflow-x: auto;
}

.table {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  overflow: visible;
  border-collapse: collapse;
  border-spacing: 0px;
  outline: currentcolor none 0px;
}

%cell {
  min-width: max-content;
  text-align: center;
  padding: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
}

.headerCell {
  @extend %cell;
  font-weight: 600;
  padding: 10px;
}

.bodyCell {
  @extend %cell;
  border-top: 1px solid black;
  font-weight: normal;
  text-overflow: ellipsis;
}

.cellContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}