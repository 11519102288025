@import '../../variables';
@import '../../media-queries';

.header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--primary-color-dark);
  min-height: 91px;
  max-height: 91px;
  padding-right: 40px;
  padding-left: 40px;
}

.titleAndUpdated {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-right: 5%;
  cursor: default;

  .title {
    font-size: 28px;
    color: white;
    font-weight: bold;

    @include lt-sm {
      font-size: 14px;
    }

    @include lt-md {
      font-size: 21px;
    }
  }

  .lastUpdated {
    font-size: 12px;
    color: white;

    @include lt-sm {
      font-size: 10px;
    }
  }
}

.left {
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  margin: 20px 20px 20px 40px;
}