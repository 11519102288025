@import '../../variables.scss';

$width: 7px;

.container {
  display: flex;
  flex-direction: row;
  margin-right: 10%;
  cursor: pointer;

  &:hover {
    .item {
      background-color: orange;
      width: $width;
      height: $width;
      margin: calc($width / 3);
    }
  }
}

.row {
  display: flex;
  flex-direction: column;
}

.item {
  background-color: white;
  width: $width;
  height: $width;
  margin: calc($width / 3);
}

.navigationPane {
  display: flex;
  flex-direction: column;
  background-color: #081c28e6; // TODO hardcoded color
  padding: 0px 40px 25px 40px;
  align-items: center;
}

.title {
  color: white;
  font-size: larger;
  margin-top: 25px;
  text-align: center;
}

.gradientUnderline {
  height: 1px;
  margin-top: 1px;
  min-width: 100%;
  background: rgb(8, 28, 40);
  background: linear-gradient(90deg, rgba(8, 28, 40, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(8, 28, 40, 1) 100%);
}