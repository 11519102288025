.variables {
  // used only in other css variables
  --primary-color: #1d303b;
  --primary-color-dark: #081c28;
  // used in different component elements to attract attention
  --secondary-color: #e4ac00;

  /* ===== MAIN ===== */
  // background-color for: plates, table rows, tabs and all other components that have some content
  --surface-color: #ffffff;
  // background-color for: page, table and all other wrappers
  --background-color: #f5f6f7;
  --secondary-backgrond-color: #a3abb2;
  // background-color: for popup windows and other stuff to not blend with --background-color
  --popup-background-color: #f0f1f3;
  // some decorations elements like borders
  --border-color: rgba(0, 0, 0, 0.15);
  // color of selected range in datepicker
  --selection-color: rgba(228, 172, 0, 0.15);
  // color of separators between buttons or other elements
  --separator-color: #dadee2;
  // color of some elements when they are hovered
  --hover-color: rgba(140, 140, 140, 0.2);
  // color of icons
  --icon-color: rgba(0, 0, 0, 0.65);
  // almost all text in app
  --text-color: rgba(0, 0, 0, 0.85);
  // some descriptions, not selected columns in tables etc
  --text-secondary-color: rgba(0, 0, 0, 0.45);
  // border color of buttons
  --button-color: #727f8a;
  // sidebar color
  --sidebar-color: var(--primary-color);
  // radio button color
  --radio-button-color: rgba(255, 255, 255, 0.55);
  // color fox box-shadow property
  --shadow-color: rgba(0, 21, 33, 0.08);

  // this for accent elements to make a contrast in app
  --accent-background-color: var(--primary-color-dark);
  --accent-surface-color: var(--primary-color);
  --accent-text-color: rgba(255, 255, 255, 0.85);
  --accent-text-secondary-color: rgba(255, 255, 255, 0.45);
  --accent-button-color: rgba(255, 255, 255, 0.85);
  --accent-icon-color: rgba(255, 255, 255, 0.65);
  --accent-border-color: rgba(255, 255, 255, 0.15);
  /* ===== ADDITIONAL ===== */

  --green-color: #26c022;
  --blue-color: #164559;
  --red-color: #ff0000;
  --yellow-color: #e4ac00;
  --gray-color: gray;

  // impact tracking chart
  --optimized-color: var(--primary-color);
  --baseline-color: var(--gray-color);
  --actual-color: var(--secondary-color);

  // used for recommendations statuses
  --approve-color: var(--green-color);
  --review-color: var(--blue-color);
  --decline-color: var(--red-color);
  --other-status-color: var(--gray-color);
  // used for sensor checker
  --availability-color: var(--green-color);

  --table-changes: var(--yellow-color);

  --tooltip-text-color: rgba(255, 255, 255, 0.85);
  --tooltip-background-color: #861F41;

  --sankey-chart-text-color: rgba(255, 255, 255, 0.85);
  --sankey-chart-background-color: #081c28;

  --checkbox-color: rgba(0, 0, 0, 0.1);

  --progress-bar-main: #fdfdfe;
  --progress-bar-secondary: #1d303b;

  --uplift-positive: var(--green-color);
  --uplift-negative: #fd7700;
}
