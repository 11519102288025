@import '../../variables.scss';
@import '../../media-queries.scss';

.summary {
  background-color: $accordion-summary-color;
}

.detailsContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 80px;
}

.dataContainer {
  margin-top: $seasonality-vertical-spacing;

  @include lt-lg {
    margin-left: $app-horizontal-margin;
    // margin-left: $data-table-margins;
    margin-right: $app-horizontal-margin;
    // margin-right: $data-table-margins;
  }
}

.graphContainer {
  margin-top: $seasonality-vertical-spacing;
  margin-bottom: $seasonality-vertical-spacing*3;

  @include lt-lg {
    padding-bottom: $app-vertical-margin;
  }
}