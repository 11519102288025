
$spacing: 10px;

.container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: $spacing;
  min-width: none;
  max-width: none;
}